<template>
  <section class="space partners">
    <div class="container">
      <div class="cmn_heading_area">
        <h2 class="heading">Featured <span class="cm-color">Partners</span></h2>
        <p class="small_desc text-dark montM">
          Our platform simply is actively looking for partners.
          <br />
          If you are interested you reach out to us from the contact us page.
        </p>
      </div>
      <ul>
        <li><img src="@/assets/images/about-partner2.png" alt="" /></li>
        <li><img src="@/assets/images/about-partner2.png" alt="" /></li>
        <li><img src="@/assets/images/about-partner2.png" alt="" /></li>
        <li><img src="@/assets/images/about-partner2.png" alt="" /></li>
        <li><img src="@/assets/images/about-partner2.png" alt="" /></li>
        <li><img src="@/assets/images/about-partner2.png" alt="" /></li>
        <li><img src="@/assets/images/about-partner2.png" alt="" /></li>
        <li><img src="@/assets/images/about-partner2.png" alt="" /></li>
        <li><img src="@/assets/images/about-partner2.png" alt="" /></li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {};
</script>
