import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body_wrap about_page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!
  const _component_get_in_touch_section = _resolveComponent("get-in-touch-section")!
  const _component_our_offer_section = _resolveComponent("our-offer-section")!
  const _component_featured_partners_section = _resolveComponent("featured-partners-section")!
  const _component_company_info_section = _resolveComponent("company-info-section")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_header_section),
      _createVNode(_component_get_in_touch_section),
      _createVNode(_component_our_offer_section),
      _createVNode(_component_featured_partners_section),
      _createVNode(_component_company_info_section)
    ]),
    _createVNode(_component_loader, { show: _ctx.isLoading }, null, 8, ["show"])
  ], 64))
}