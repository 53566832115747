
import Loader from "@/components/ui/Loader.vue";
import { defineComponent } from "@vue/runtime-core";
import FeaturedPartnersSection from "../about/FeaturedPartnersSection.vue";
import GetInTouchSection from "../about/GetInTouchSection.vue";
import HeaderSection from "../about/HeaderSection.vue";
import OurOfferSection from "../about/OurOfferSection.vue";
import CompanyInfoSection from "./CompanyInfoSection.vue";
import "./styles.css";
export default defineComponent({
  name: "About",
  data() {
    return {
      isLoading: true,
    };
  },
  components: {
    GetInTouchSection,
    OurOfferSection,
    HeaderSection,
    FeaturedPartnersSection,
    CompanyInfoSection,
    Loader,
  },
  mounted() {
    this.isLoading = false;
  },
});
