<template>
  <section class="space company_info">
    <div class="container">
      <div class="company_inner_block mb-4">
        <div class="row align-items-center">
          <div class="col-md-6 company_text_area">
            <div class="info_content text-white">
              <div class="cmn_heading_area">
                <h2 class="heading">Our Story</h2>
              </div>
              <p>
                Claudio was only 22 years old when he first had the idea of
                TopPick. He used to struggle to find interesting topics for his
                language conversations, which are essential for developing a
                broad vocabulary in a foreign language. He started developing
                TopPick with his team and, after a few years of struggle, the
                platform started to gain traction among teachers and language
                enthusiasts as the main source of conversational starters
              </p>
              <router-link
                :to="{
                  name: 'BlogDetail',
                  params: { slug: 'toppick-history' },
                }"
                ><span>Read more</span>
                <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i
              ></router-link>
            </div>
          </div>
          <div class="col-md-6 company_image_area">
            <div class="info_image text-center">
              <img src="@/assets/images/about_story.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="company_inner_block mb-4">
        <div class="row align-items-center">
          <div class="col-md-6 company_image_area">
            <div class="info_image text-center">
              <img src="@/assets/images/about-mission.png" alt="" />
            </div>
          </div>
          <div class="col-md-6 company_text_area">
            <div class="info_content text-white">
              <div class="cmn_heading_area">
                <h2 class="heading">Our Mission</h2>
              </div>
              <p>
                We strive to assist everyone who's as curious as we are by
                helping them aim that curiosity at the right targets. We do this
                by providing you with a gigantic library of topics for you to
                explore at your convenience. By spending some time every day
                perusing our library you're going to quickly realize that you've
                acquired a decent amount of knowledge regarding subjects you
                hadn't put much thought into before.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="company_inner_block">
        <div class="row align-items-center">
          <div class="col-md-6 company_text_area">
            <div class="info_content text-white">
              <div class="cmn_heading_area">
                <h2 class="heading">Our Vision</h2>
              </div>
              <p>
                We are quite ambitious here at TopPick. We'll always be doing
                our best to make sure that every language learner, educator,
                writer, or even someone who's just fascinated by the world
                around them finds all the resources needed to satisfy their
                desires. This guides our vision of improving communication
                between individuals and always coming out of a conversation with
                new profound knowledge that'll help us advance as a global
                society.
              </p>
            </div>
          </div>
          <div class="col-md-6 company_image_area">
            <div class="info_image text-center">
              <img src="@/assets/images/about-vision.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
